function shadowDOM(appName) {
	const appWrapperClassName = `${appName}-wrapper`;
	const appWrapperEls = Array.from(
		document.querySelectorAll(`.${appWrapperClassName}`),
	);

	return appWrapperEls.map(appDOM => {
		const uid = Math.floor(Math.random() * 1000);
		const appRootId = `${appName}-app-${uid}`;

		const supportsShadowDOM =
			appDOM.shadowRoot === null || typeof appDOM.shadowRoot === "object";

		// If loading in application - initialize shadow dom
		let reactDOM;

		// Create div element for react to render into
		const reactRoot = document.createElement("div");
		reactRoot.setAttribute("id", appRootId);
		reactRoot.setAttribute("class", appRootId);

		// check modern browsers
		if (supportsShadowDOM) {
			if (appDOM.shadowRoot === null) {
				appDOM.attachShadow({ mode: "open" });
			}

			// Append react root to shadow root
			appDOM.shadowRoot.appendChild(reactRoot);

			// Set parent outer DOM for modals
			reactDOM = appDOM.shadowRoot;

			// Retarget all events in shadow dom for React
		} else {
			// Append react root to wrapper element
			appDOM.appendChild(reactRoot);

			// Set parent outer DOM for modals
			reactDOM = document;
		}

		return {
			appDOM,
			reactRoot,
			reactDOM,
		};
	});
}

function externalStylesheet(reactDOM, stylesheet) {
	if (stylesheet) {
		const isURL = require("validator/lib/isURL");

		if (
			isURL(stylesheet, {
				require_tld: false,
				protocols: ["https"],
				allow_underscores: true,
			})
		) {
			const link = document.createElement("link");
			link.type = "text/css";
			link.rel = "stylesheet";

			link.href = stylesheet;

			if (reactDOM === document) {
				document.body.appendChild(link);
			} else {
				reactDOM.appendChild(link);
			}
		} else {
			console.warn(
				"External stylesheet provided did not have a 'https' protocol or was not a valid url.",
			);
		}
	}
}

/**
 * Function that handles injecting Fonts into hosting application
 * @param appDOM {HTMLElement}
 * @param stylesheet {string}
 * @category ShadowDOM
 */
function injectFont(appDOM, stylesheet) {
	if (stylesheet) {
		const isURL = require("validator/lib/isURL");

		if (
			isURL(stylesheet, {
				require_tld: false,
				protocols: ["https"],
				allow_underscores: true,
			})
		) {
			const link = document.createElement("link");
			link.type = "text/css";
			link.rel = "stylesheet";

			link.href = stylesheet;

			appDOM.appendChild(link);
		} else {
			console.warn(
				"External stylesheet provided did not have an 'https' protocol or was not valid url.",
			);
		}
	}
}

export { shadowDOM, externalStylesheet, injectFont };
